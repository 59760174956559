<template>
  <div
    class="absolute bottom-5 top-5 left-5 z-10 bg-white shadow grid grid-cols-2 pr-4 pl-6 pt-5 w-module"
    style="grid-template-rows: auto auto auto auto 1fr; width: 603px"
    :class="isGeoAdmin ? 'invisible' : 'visible'"
  >
    <div
      class="text-darkblue col-start-1 col-end-2 text-xl font-bold whitespace-no-wrap font-SourceSansPro"
    >
      {{ $t('admin-panel.title') }}
    </div>
    <div class="flex justify-end col-start-2 items-center">
      <img
        v-if="aModule === 'admin_users'"
        class="mr-5 cursor-pointer"
        src="@/assets/icons/history.svg"
        style="width: 18px"
        alt=""
        @click="$emit('show-history', { objects: 'users' })"
      />
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      />
    </div>
    <div class="flex flex-col mt-6 relative col-start-1 col-end-3">
      <p
        class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
      >
        {{ $t('admin-panel.company') }}
      </p>
      <div
        class="flex justify-between"
        :class="{ isInvalid: isCompanyMissing }"
      >
        <el-select
          class="adminSelect w-3/5"
          v-model="company"
          filterable="filterable"
          multiple="multiple"
          collapse-tags="collapse-tags"
        >
          <el-option
            class="text-ellipsis"
            style="max-width: 265px"
            v-for="item in companies"
            :key="item.id"
            :value="item.id"
            :label="item.name || '-'"
          />
        </el-select>
        <skif-button v-if="aModule === 'admin_units'" @click="openWialon">
          {{ $t('adminPanelTranlsater.importWialon') }}
        </skif-button>
        <skif-button v-if="aModule === 'admin_geozones'" @click="openKml">
          {{ $t('adminPanelTranlsater.importKML') }}
        </skif-button>
      </div>

      <transition name="el-zoom-in-top">
        <div
          v-if="isCompanyMissing"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ $t('admin-panel.is-company') }}
        </div>
      </transition>
    </div>
    <div class="flex flex-col col-start-1 col-end-3 mt-6">
      <p
        class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
      >
        {{ $t('admin-panel.module') }}
      </p>
      <div class="flex justify-between w-full">
        <el-select
          class="mr-4"
          v-model="aModule"
          filterable="filterable"
          style="width: 70%"
        >
          <el-option
            class="text-ellipsis"
            style="max-width: 200px"
            v-for="item in adminModules"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          />
        </el-select>
        <div class="relative w-full" v-if="aModule !== 'admin_tariffs'">
          <div
            class="absolute inset-y-0 flex items-center cursor-pointer"
            style="right: 8px"
            @click="handlSearch"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6 9.8a7.8 7.8 0 10-7.8 7.8 7.819 7.819 0 005.076-1.872l.324.324V17l6 6 1.8-1.8-6-6h-.948l-.324-.324A7.819 7.819 0 0017.6 9.8zm-13.201 0c0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4z"
                fill="#CFDBEB"
              />
            </svg>
          </div>
          <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
          <input
            class="rounded focus_outline-none pl-4 pr-8 w-full text-sm font-medium border border-borderColor"
            type="text"
            style="height: 35px"
            v-model="filterString"
            @keyup.enter="handlSearch"
            :placeholder="$t('search')"
          />
        </div>
      </div>
      <div class="flex mt-3">
        <button
          type="button"
          class="mr-8 px-3 font-semibold focus_outline-none hover_text-headerText transition-colors duration-300 text-annotationColor"
          width="24"
          v-if="aModule === 'admin_companies'"
          @click="inviteToCompany"
        >
          {{ $t('adminPanelTranlsater.invite') }}
        </button>
      </div>
    </div>
    <div
      class="flex flex-col col-start-1 col-end-3 pb-3 border-b border-divider"
    >
      <div v-if="aModule === 'data_deletions'" class="data-deletions flex">
        <div class="object mr-3">
          <p
            class="block text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('adminPanelTranlsater.object') }}
          </p>
          <el-select
            class="adminSelect"
            v-model="dataSelectDeletions"
            filterable="filterable"
            collapse-tags="collapse-tags"
          >
            <el-option
              class="text-ellipsis"
              style="max-width: 227px"
              v-for="item in selectObject"
              :key="item.id"
              :value="item.id"
              :label="item.name || '-'"
            />
          </el-select>
        </div>
        <div
          class="period block text-annotationColor text-sm font-SourceSansPro font-semibold"
        >
          <p
            class="block text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('admin-panel.period') }}
          </p>

          <el-date-picker
            v-if="aModule === 'data_deletions'"
            :clearable="false"
            :picker-options="pickerOptions"
            v-model="dateDeletionsDate"
            type="datetimerange"
            range-separator="-"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :start-placeholder="$t('reports.not-select')"
            :end-placeholder="$t('reports.not-select')"
          />
        </div>
      </div>

      <p
        class="inline-block text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
        v-if="aModule === 'admin_actions'"
      >
        {{ $t('admin-panel.period') }}
      </p>
      <el-date-picker
        v-if="aModule === 'admin_actions'"
        :clearable="false"
        :picker-options="pickerOptions"
        v-model="dates"
        type="datetimerange"
        range-separator="-"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        :start-placeholder="$t('reports.not-select')"
        :end-placeholder="$t('reports.not-select')"
      />
    </div>
    <div class="flex flex-col col-start-1 col-end-3 overflow-y-auto">
      <div
        class="cursor-pointer hover_bg-reallylightblue flex justify-between px-4 py-2"
        v-if="aModule === 'admin_actions'"
        @click="getFinanceReport"
      >
        <span class="font-bold text-headerText text-sm">
          {{ $t('admin-panel.finance') }}
        </span>
        <!-- <el-popover trigger="hover" placement="bottom-start">
          <a
            class="cursor-pointer font-bold text-annotationColor mb-1"
            :href="financeReport"
          >
            {{ $t('admin-panel.download-finance') }}
          </a>
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            slot="reference"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              fill="#91B4E7"
            />
          </svg>
        </el-popover> -->
      </div>

      <!-- admin_retranslators -->
      <!-- <div
        class="col-start-1 col-end-3 flex justify-end mt-2"
        v-if="aModule === 'admin_retranslators' && !elementsArr.length"
      > -->
      <!-- <svg
          class="mr-1 cursor-pointer"
          v-if="aModule === 'admin_retranslators'"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="addRetranslators"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.75 4.75C8.75 4.55109 8.67098 4.36032 8.53033 4.21967C8.38968 4.07902 8.19891 4 8 4C7.80109 4 7.61032 4.07902 7.46967 4.21967C7.32902 4.36032 7.25 4.55109 7.25 4.75V7.25H4.75C4.55109 7.25 4.36032 7.32902 4.21967 7.46967C4.07902 7.61032 4 7.80109 4 8C4 8.19891 4.07902 8.38968 4.21967 8.53033C4.36032 8.67098 4.55109 8.75 4.75 8.75H7.25V11.25C7.25 11.4489 7.32902 11.6397 7.46967 11.7803C7.61032 11.921 7.80109 12 8 12C8.19891 12 8.38968 11.921 8.53033 11.7803C8.67098 11.6397 8.75 11.4489 8.75 11.25V8.75H11.25C11.4489 8.75 11.6397 8.67098 11.7803 8.53033C11.921 8.38968 12 8.19891 12 8C12 7.80109 11.921 7.61032 11.7803 7.46967C11.6397 7.32902 11.4489 7.25 11.25 7.25H8.75V4.75Z"
            fill="#91B4E7"
          />
        </svg> -->
      <!-- </div> -->

      <div
        class="col-start-1 col-end-3"
        style="height: 100%"
        v-if="aModule !== 'admin_actions'"
      >
        <table class="table-fixed" v-if="aModule === 'admin_tariffs'">
          <thead class="text-left">
            <th class="bg-white top-0 sticky w-48 pl-2">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('admintariff.company') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-48 pl-2">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('admintariff.tariff') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-48 pl-2 text-right">
              <span
                class="text-darkblue cursor-pointer"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('admintariff.date') }}
              </span>
            </th>
          </thead>
          <tbody style="border-top: 4px solid white">
            <tr
              class="cursor-pointer hover_bg-reallylightblue"
              v-for="(tarif, index) in elementsArr"
              :key="index"
              @click="getTarif(tarif, index)"
            >
              <td class="ellipsis py-2">
                <span
                  class="text-darkblue cursor-pointer"
                  style="font-weight: bold; font-size: 14px"
                >
                  {{ tarif.companyDetails.name }}
                </span>
              </td>
              <td class="py-2">
                <span
                  class="text-darkblue cursor-pointer"
                  style="font-weight: bold; font-size: 14px"
                >
                  {{ tarif.rate ? tarif.rate : '0' }}
                  {{ tarif.currency.display_name }} {{ $t('admintariff.for') }}
                  {{ tarif.tariff.units }}
                  {{ $t('admintariff.objects') }}
                  {{ getDays(tarif.tariff) }}
                </span>
                <span
                  class="text-darkblue cursor-pointer"
                  style="font-weight: bold"
                >
                  {{ tarif.tarif }}
                </span>
              </td>
              <td class="py-2 text-right">
                <span
                  class="text-darkblue cursor-pointer mr-2"
                  style="font-weight: bold; font-size: 14px"
                  v-if="tarif.valid_to"
                >
                  {{ tarif.valid_to.substring(0, tarif.valid_to.length - 8) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-fixed" v-else-if="aModule === 'admin_integrators'">
          <thead class="text-left">
            <th class="bg-white top-0 sticky z-10 w-6">
              <skif-checkbox
                class="p-2"
                v-model="isAllChecked"
                :indeterminate="isIndeterminate"
                @change="checkAllUsers($event)"
              />
            </th>
            <th class="bg-white top-0 sticky w-64 pl-2">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.name') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-64 pl-2">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.phone') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky text-right" style="width: 82px">
              <svg
                class="mr-2 cursor-pointer"
                @click="addIntegrator"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.75 4.75C8.75 4.55109 8.67098 4.36032 8.53033 4.21967C8.38968 4.07902 8.19891 4 8 4C7.80109 4 7.61032 4.07902 7.46967 4.21967C7.32902 4.36032 7.25 4.55109 7.25 4.75V7.25H4.75C4.55109 7.25 4.36032 7.32902 4.21967 7.46967C4.07902 7.61032 4 7.80109 4 8C4 8.19891 4.07902 8.38968 4.21967 8.53033C4.36032 8.67098 4.55109 8.75 4.75 8.75H7.25V11.25C7.25 11.4489 7.32902 11.6397 7.46967 11.7803C7.61032 11.921 7.80109 12 8 12C8.19891 12 8.38968 11.921 8.53033 11.7803C8.67098 11.6397 8.75 11.4489 8.75 11.25V8.75H11.25C11.4489 8.75 11.6397 8.67098 11.7803 8.53033C11.921 8.38968 12 8.19891 12 8C12 7.80109 11.921 7.61032 11.7803 7.46967C11.6397 7.32902 11.4489 7.25 11.25 7.25H8.75V4.75Z"
                  fill="#91B4E7"
                />
              </svg>
              <button
                class="h-9 group relative link mr-1"
                type="button"
                @click="deleteElements"
                v-if="elementsSelected.length"
              >
                <svg
                  class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#91B4E7"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                  />
                </svg>
                <skif-badge
                  flights="flights"
                  :value="elementsSelected.length"
                />
              </button>
            </th>
          </thead>
          <tbody
            is="transition-group"
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
          >
            <tr
              class="cursor-pointer hover_bg-reallylightblue"
              v-for="(unit, index) in elementsArr"
              :key="index"
            >
              <td class="w-10">
                <skif-checkbox
                  class="p-2"
                  v-model="unit.isInArray"
                  @change="changeChecked(unit, $event)"
                />
              </td>
              <td class="ellipsis pl-2" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.name"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.name }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis pl-2">
                <span class="leading-10 nameTable">{{ unit.phone }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          class="w-full table-fixed"
          v-else-if="aModule === 'commands_templates'"
        >
          <thead class="text-left">
            <th class="bg-white top-0 sticky z-10 w-6">
              <skif-checkbox
                class="p-2"
                v-model="isAllChecked"
                :indeterminate="isIndeterminate"
                @change="checkAllUsers($event)"
              />
            </th>
            <th class="bg-white top-0 sticky pl-2" style="width: 105px">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.typeTerminal') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.name') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky" style="width: 160px">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.command') }}
              </span>
            </th>
            <th
              class="bg-white top-0 sticky text-right h-10"
              style="width: 60px"
            >
              <div class="w-full flex justify-between items-center">
                <button
                  class="h-9 group relative link focus_outline-none"
                  type="button"
                  v-if="elementsSelected.length"
                  @click="deleteElements"
                >
                  <svg
                    class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#91B4E7"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                    />
                  </svg>
                  <skif-badge
                    flights="flights"
                    :value="elementsSelected.length"
                  />
                </button>
                <svg
                  class="mr-1 cursor-pointer ml-1 h-full"
                  @click="isAddCommandTemplate = true"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.75 4.75C8.75 4.55109 8.67098 4.36032 8.53033 4.21967C8.38968 4.07902 8.19891 4 8 4C7.80109 4 7.61032 4.07902 7.46967 4.21967C7.32902 4.36032 7.25 4.55109 7.25 4.75V7.25H4.75C4.55109 7.25 4.36032 7.32902 4.21967 7.46967C4.07902 7.61032 4 7.80109 4 8C4 8.19891 4.07902 8.38968 4.21967 8.53033C4.36032 8.67098 4.55109 8.75 4.75 8.75H7.25V11.25C7.25 11.4489 7.32902 11.6397 7.46967 11.7803C7.61032 11.921 7.80109 12 8 12C8.19891 12 8.38968 11.921 8.53033 11.7803C8.67098 11.6397 8.75 11.4489 8.75 11.25V8.75H11.25C11.4489 8.75 11.6397 8.67098 11.7803 8.53033C11.921 8.38968 12 8.19891 12 8C12 7.80109 11.921 7.61032 11.7803 7.46967C11.6397 7.32902 11.4489 7.25 11.25 7.25H8.75V4.75Z"
                    fill="#91B4E7"
                  />
                </svg>
              </div>
            </th>
          </thead>
          <tbody
            is="transition-group"
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
          >
            <tr
              class="cursor-pointer hover_bg-reallylightblue"
              v-for="(unit, index) in elementsArr"
              :key="index"
            >
              <td class="w-10">
                <skif-checkbox
                  class="p-2"
                  v-model="unit.isInArray"
                  @change="changeChecked(unit, $event)"
                />
              </td>
              <td class="ellipsis pl-2" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.terminal_type"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.terminal_type }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis pl-2" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.name"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.name }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis text-left" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.command_template"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.command_template }}
                  </span>
                </el-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="w-full table-fixed" v-else-if="aModule === 'feedback'">
          <thead class="text-left">
            <th class="bg-white top-0 sticky z-10 w-6">
              <skif-checkbox
                class="p-2"
                v-model="isAllChecked"
                :indeterminate="isIndeterminate"
                @change="checkAllUsers($event)"
              />
            </th>
            <th class="bg-white top-0 sticky pl-2" style="width: 105px">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.user') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2" style="width: 50px">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.grade') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2" style="width: 105px">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.type') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2" style="width: 105px">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.message') }}
              </span>
            </th>
          </thead>
          <tbody
            is="transition-group"
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
          >
            <tr
              class="cursor-pointer hover_bg-reallylightblue"
              v-for="(unit, index) in elementsArr"
              :key="index"
            >
              <td class="w-10">
                <skif-checkbox
                  class="p-2"
                  v-model="unit.isInArray"
                  @change="changeChecked(unit, $event)"
                />
              </td>
              <td class="ellipsis pl-2" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.user.name"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.user.name }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis pl-2 w-4" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.rating + ''"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.rating + '' }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis text-left w-8" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.type.value"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.type.value }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis text-left" @click="getElement(unit)">
                <el-tooltip
                  popper-class="leva"
                  :open-delay="700"
                  effect="dark"
                  :content="unit.message"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.message }}
                  </span>
                </el-tooltip>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Платежи -->
        <table class="table-fixed" v-else-if="aModule === 'payments'">
          <thead class="text-left">
            <th
              :class="[
                'bg-white',
                'top-0',
                'sticky',
                'w-48',
                { 'opacity-0': isShowCreatePayment }
              ]"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.company') }}
              </span>
            </th>
            <th
              :class="[
                'bg-white',
                'top-0',
                'sticky',
                'w-48',
                { 'opacity-0': isShowCreatePayment }
              ]"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.user') }}
              </span>
            </th>
            <th
              :class="[
                'bg-white',
                'top-0',
                'sticky',
                'w-48',
                { 'opacity-0': isShowCreatePayment }
              ]"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.total') }}
              </span>
            </th>
            <th
              :class="[
                'bg-white',
                'top-0',
                'sticky',
                'w-48',
                { 'opacity-0': isShowCreatePayment }
              ]"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.date') }}
              </span>
            </th>
            <th
              :class="[
                'bg-white',
                'top-0',
                'sticky',
                'w-48',
                { 'opacity-0': isShowCreatePayment }
              ]"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.numberType') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-48">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2 text-right"
                :class="[
                  'add-icon',
                  { 'add-icon--active': isShowCreatePayment }
                ]"
                style="font-size: 14px; color: #91b4e7"
              >
                <AddIcon @click.native="showCreatePayment" />
              </span>
            </th>
          </thead>
          <tbody
            is="transition-group"
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
            class="payment-table"
          >
            <tr
              v-for="(unit, index) in elementsArr"
              :key="index"
              class="cursor-pointer hover_bg-reallylightblue"
              :class="{
                'string--isRed': unit.operation_type === 'WITHDRAWAL',
                'string--isBlue': unit.operation_type === 'CASH_DEPOSIT'
              }"
            >
              <td class="w-36 payments">
                <p class="title">{{ unit.companyDetails.name }}</p>
                <div class="amount-wrapper">
                  <span class="text"
                    >{{ unit.amount_before }} {{ unit.currency.code }}</span
                  >
                  <ArrowIcon />
                  <span
                    :class="[
                      'text',
                      { 'text--balanceMinus': unit.amount_after < 0 }
                    ]"
                    >{{ unit.amount_after }} {{ unit.currency.code }}</span
                  >
                </div>
              </td>
              <td class="w-36 payments">
                <p class="title">{{ unit.user_name }}</p>
                <p class="text">{{ unit.user_email }}</p>
              </td>
              <td class="w-14 payments">
                <p class="title">{{ unit.amount }}</p>
                <p class="text">{{ unit.currency.code }}</p>
              </td>
              <td class="w-20 payments">
                <p class="title">{{ humanDate(unit.timestamp).date }}</p>
                <p class="text">{{ humanDate(unit.timestamp).time }}</p>
              </td>
              <td class="w-20 payments">
                <p class="title">{{ unit.number }}</p>
                <p class="text">
                  {{ humanOperationType(unit.operation_type) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Удаленные данные -->
        <table
          class="table-fixed w-full"
          v-else-if="aModule === 'data_deletions'"
        >
          <thead>
            <th class="bg-white top-0 sticky z-10 w-6">
              <skif-checkbox
                class="p-2"
                v-model="isAllChecked"
                :indeterminate="isIndeterminate"
                @change="checkAllUsers($event)"
              />
            </th>
            <th class="bg-white top-0 sticky pl-2 text-left">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.object') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2 text-left">
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 12px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.period') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2 text-right">
              <div class="wrapper">
                <OutTrashIcon
                  @click.native="removeDataDeletions"
                  class="relative left-2"
                />
                <div class="badge">
                  <skif-badge
                    class="absolute top-0 right-2"
                    flights="flights"
                    :value="elementsSelected.length"
                  />
                </div>
              </div>
            </th>
          </thead>
          <tbody
            is="transition-group"
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
          >
            <tr
              class="cursor-pointer"
              v-for="(unit, index) in elementsArr"
              :key="index"
            >
              <td class="w-10">
                <skif-checkbox
                  class="p-2"
                  v-model="unit.isInArray"
                  @change="changeChecked(unit, $event)"
                />
              </td>
              <td class="ellipsis pl-2" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.name"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.unit.name }}
                  </span>
                </el-tooltip>
              </td>
              <td class="ellipsis pl-2 inline">
                <span class="leading-10 nameTable"
                  >{{ unit.start_date }} - {{ unit.finish_date }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <table class="w-full table-fixed" v-else>
          <thead class="text-left">
            <th class="bg-white top-0 sticky z-10 w-6">
              <skif-checkbox
                class="p-2"
                v-model="isAllChecked"
                :indeterminate="isIndeterminate"
                @change="checkAllUsers($event)"
              />
            </th>
            <th
              class="bg-white top-0 sticky pl-2"
              v-if="aModule === 'admin_retranslators'"
              :style="aModule === 'admin_retranslators' ? 'width: 9rem' : ''"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ 'IMEI' }}
              </span>
            </th>
            <th class="bg-white top-0 sticky pl-2" v-else>
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{
                  aModule === 'admin_retranslators'
                    ? 'IMEI'
                    : $t('mailing.name')
                }}
              </span>
            </th>
            <th
              class="bg-white top-0 sticky pl-2"
              :style="aModule === 'admin_retranslators' ? 'width: 7rem' : ''"
              v-if="aModule === 'admin_retranslators'"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer mr-2"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ 'Сервер' }}
              </span>
            </th>
            <!-- Протокол -->
            <th
              v-if="aModule === 'admin_retranslators'"
              class="bg-white top-0 sticky"
              style="width: 122px"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer"
                style="font-size: 14px; color: #91b4e7"
              >
                {{ $t('adminPanelTranlsater.protocol') }}
              </span>
            </th>

            <!-- Компания -->
            <th
              class="bg-white top-0 sticky pl-1"
              :style="aModule === 'admin_retranslators' ? 'width: 122px' : ''"
            >
              <span
                class="text-darkblue leading-10 cursor-pointer"
                style="font-size: 14px; color: #91b4e7"
              >
                {{
                  aModule === 'admin_companies'
                    ? $t('admin-panel.timezone')
                    : aModule === 'admin_users'
                    ? 'Email'
                    : $t('admin-panel.company')
                }}
              </span>
              <button
                class="h-9 group relative link mr-2"
                type="button"
                v-if="
                  (aModule === 'admin_users' && elementsSelected.length) ||
                  (aModule === 'admin_retranslators' && elementsSelected.length)
                "
                @click="deleteElements"
              >
                <svg
                  class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#91B4E7"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                  />
                </svg>
                <skif-badge
                  flights="flights"
                  :value="elementsSelected.length"
                />
              </button>
            </th>

            <th
              v-if="aModule === 'admin_retranslators'"
              class="bg-white top-0 sticky pl-1"
              :style="aModule === 'admin_retranslators' ? 'width: 35px' : ''"
            >
              <svg
                class="cursor-pointer"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="addRetranslators"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.75 4.75C8.75 4.55109 8.67098 4.36032 8.53033 4.21967C8.38968 4.07902 8.19891 4 8 4C7.80109 4 7.61032 4.07902 7.46967 4.21967C7.32902 4.36032 7.25 4.55109 7.25 4.75V7.25H4.75C4.55109 7.25 4.36032 7.32902 4.21967 7.46967C4.07902 7.61032 4 7.80109 4 8C4 8.19891 4.07902 8.38968 4.21967 8.53033C4.36032 8.67098 4.55109 8.75 4.75 8.75H7.25V11.25C7.25 11.4489 7.32902 11.6397 7.46967 11.7803C7.61032 11.921 7.80109 12 8 12C8.19891 12 8.38968 11.921 8.53033 11.7803C8.67098 11.6397 8.75 11.4489 8.75 11.25V8.75H11.25C11.4489 8.75 11.6397 8.67098 11.7803 8.53033C11.921 8.38968 12 8.19891 12 8C12 7.80109 11.921 7.61032 11.7803 7.46967C11.6397 7.32902 11.4489 7.25 11.25 7.25H8.75V4.75Z"
                  fill="#91B4E7"
                />
              </svg>
            </th>

            <!-- Кнопка создания компании -->
            <th class="bg-white top-0 sticky pl-1">
              <span
                v-if="aModule === 'admin_companies'"
                class="text-darkblue leading-10 cursor-pointer"
                style="font-size: 14px; color: #91b4e7"
                @click="inviteUser"
              >
                {{ $t('adminPanelTranlsater.addCompany') }}
              </span>
            </th>

            <th
              v-if="aModule === 'admin_terminals'"
              class="bg-white top-0 sticky pl-2 text-center cursor-pointer"
              style="width: 25px"
              @click="addTerminals()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.75 4.75C8.75 4.55109 8.67098 4.36032 8.53033 4.21967C8.38968 4.07902 8.19891 4 8 4C7.80109 4 7.61032 4.07902 7.46967 4.21967C7.32902 4.36032 7.25 4.55109 7.25 4.75V7.25H4.75C4.55109 7.25 4.36032 7.32902 4.21967 7.46967C4.07902 7.61032 4 7.80109 4 8C4 8.19891 4.07902 8.38968 4.21967 8.53033C4.36032 8.67098 4.55109 8.75 4.75 8.75H7.25V11.25C7.25 11.4489 7.32902 11.6397 7.46967 11.7803C7.61032 11.921 7.80109 12 8 12C8.19891 12 8.38968 11.921 8.53033 11.7803C8.67098 11.6397 8.75 11.4489 8.75 11.25V8.75H11.25C11.4489 8.75 11.6397 8.67098 11.7803 8.53033C11.921 8.38968 12 8.19891 12 8C12 7.80109 11.921 7.61032 11.7803 7.46967C11.6397 7.32902 11.4489 7.25 11.25 7.25H8.75V4.75Z"
                  fill="#91B4E7"
                />
              </svg>
            </th>

            <th
              class="bg-white top-0 sticky text-left"
              style="width: 40px"
              v-if="
                (canDelete &&
                  aModule === 'admin_companies' &&
                  (currentRole === 'CONTROLLER' ||
                    currentRole === 'TARIFF_EDITOR')) ||
                (canDelete && aModule !== 'admin_retranslators')
              "
            >
              <button
                class="h-9 group relative link"
                type="button"
                v-if="stateIcon === 'select'"
                @click="deleteElements"
              >
                <svg
                  class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#91B4E7"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                  />
                </svg>
                <skif-badge
                  flights="flights"
                  :value="elementsSelected.length"
                />
              </button>
            </th>
          </thead>
          <!-- Массив в админ панели -->
          <tbody
            is="transition-group"
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
          >
            <tr
              class="cursor-pointer hover_bg-reallylightblue"
              v-for="(unit, index) in elementsArr"
              :key="index"
            >
              <td class="w-10">
                <skif-checkbox
                  class="p-2"
                  v-model="unit.isInArray"
                  @change="changeChecked(unit, $event)"
                />
              </td>

              <td class="ellipsis pl-2" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="
                    aModule === 'admin_terminals'
                      ? unit.imei
                        ? unit.imei
                        : unit.name
                      : aModule === 'admin_retranslators'
                      ? unit.imei
                      : unit.name
                  "
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{
                      aModule === 'admin_terminals'
                        ? unit.imei
                          ? unit.imei
                          : unit.name
                        : aModule === 'admin_retranslators'
                        ? unit.imei
                        : unit.name
                    }}
                  </span>
                </el-tooltip>
              </td>

              <td
                class="ellipsis pl-2"
                v-if="aModule === 'admin_retranslators'"
                @click="getElement(unit)"
              >
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="unit.retrans_server"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ unit.retrans_server }}
                  </span>
                </el-tooltip>
              </td>
              <!-- Протокол -->
              <td
                style="width: 122px"
                class="ellipsis"
                v-if="aModule === 'admin_retranslators'"
                @click="getElement(unit)"
              >
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="setProtocol(unit.retrans_protocol)"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{ setProtocol(unit.retrans_protocol) }}
                  </span>
                </el-tooltip>
              </td>

              <td class="ellipsis text-left" @click="getElement(unit)">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="
                    aModule === 'admin_companies'
                      ? unit.timezone_key
                      : aModule === 'admin_users'
                      ? unit.email
                      : unit.company_name
                  "
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span class="leading-10 nameTable">
                    {{
                      aModule === 'admin_companies'
                        ? unit.timezone_key || unit.timezone.key
                        : aModule === 'admin_users'
                        ? unit.email
                        : unit.company_name
                    }}
                  </span>
                </el-tooltip>
              </td>

              <td class="ellipsis text-right" v-if="aModule === 'admin_units'">
                <div class="flex w-full justify-end">
                  <div
                    class="top-0 sticky pr-4 text-center cursor-pointer"
                    style="width: 15px"
                    @click="handlQRclick(unit)"
                  >
                    <QrIcon />
                  </div>
                  <el-popover
                    trigger="hover"
                    placement="bottom-start"
                    v-model="unit.popVisible"
                  >
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      slot="reference"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                        fill="#91B4E7"
                      />
                    </svg>
                    <p
                      class="cursor-pointer font-semibold focus_outline-none hover_text-headerText transition-colors duration-300"
                      @click="copyObject(unit), (unit.popVisible = false)"
                    >
                      {{ $t('adminPanelTranlsater.copyObject') }}
                    </p>
                  </el-popover>
                </div>
              </td>

              <td
                class="ellipsis text-right"
                v-if="aModule === 'admin_report_templates'"
              >
                <!-- check why copy dont get activated -->
                <div class="flex w-full justify-end">
                  <el-popover
                    trigger="hover"
                    placement="bottom-start"
                    v-model="unit.popVisible"
                  >
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      slot="reference"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                        fill="#91B4E7"
                      />
                    </svg>
                    <p
                      class="cursor-pointer font-semibold focus_outline-none hover_text-headerText transition-colors duration-300"
                      @click="copyTemplate(unit), (unit.popVisible = false)"
                    >
                      {{ $t('adminPanelTranlsater.copyTemplate') }}
                    </p>
                  </el-popover>
                </div>
              </td>
              <td
                class="ellipsis text-center"
                v-if="aModule === 'admin_delunits'"
              >
                <el-popover
                  trigger="hover"
                  placement="bottom-start"
                  v-model="unit.popVisible"
                  ><svg
                    width="24"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    slot="reference"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                      fill="#91B4E7"
                    ></path>
                  </svg>
                  <p
                    class="cursor-pointer font-semibold focus_outline-none hover_text-headerText transition-colors duration-300"
                    @click="recoverObject(unit), (unit.popVisible = false)"
                  >
                    {{ $t('adminPanelTranlsater.recoveryObject') }}
                  </p>
                </el-popover>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-start-1 col-end-3 flex items-center justify-center">
      <el-pagination
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :pageSize="pageSize"
        :total="maxRows"
        :current-page="pageNumber"
      />
    </div>

    <companiesList
      v-if="isCompaniesList"
      @close-dialog="handlCloseDialog"
      :current="companyToCopy"
    />
    <copyReportTemplate
      v-if="isCopyTemplate"
      @close-dialog="handlCloseDialogTemplates"
      :current="templateToCopy"
    />

    <objectRecovery
      v-if="isObjectRecover"
      :current="objectToRecover"
      @close-dialog="isObjectRecover = false"
      @sucess="handlRecoveredObject"
    />

    <add-terminal
      style="height: 100%"
      v-if="isAddTerminals"
      @close="closeDialogTerminal($event)"
      :title="2"
    />

    <transition name="el-fade-in">
      <retranslators
        style="height: 100%"
        v-if="isEditRetranslators"
        @close="closeDialogRetranslators"
        :title="2"
        :item="currentUnit"
        :imeis="unitImeis"
      />
    </transition>

    <transition name="el-fade-in">
      <retranslators
        style="height: 100%"
        v-if="isAddRetranslators"
        @close="closeDialogRetranslators"
        :title="1"
        :imeis="unitImeis"
      />
    </transition>

    <transition name="el-fade-in">
      <integrators
        v-if="isEditIntegrator"
        style="height: 100%"
        :title="2"
        :item="currentUnit"
        :pay-modules="payModules"
        :organization-type="organizationType"
        :tariff-type="tariffType"
        @close="closeDialogInteg"
      />
    </transition>

    <transition name="el-fade-in">
      <integrators
        v-if="isAddIntegrator"
        style="height: 100%"
        :title="1"
        :item="newIntegrator"
        :new-integrator="isAddIntegrator"
        :organization-type="organizationType"
        @close="closeDialogInteg"
      />
    </transition>

    <editTariff
      v-if="isEditTarif"
      @close="isEditTarif = false"
      @updatetariff="handleCloseUpdateTarif"
      :currenttariff="currentTariff"
    />

    <financeTable v-if="isFinance" @close="isFinance = false" />

    <transition name="el-fade-in">
      <add-company v-if="isAddCompany" @close="closeAddCompany" />
    </transition>

    <transition name="el-fade-in">
      <feedback v-if="isFeedBack" @close="isFeedBack = false" />
    </transition>
    <transition name="el-fade-in">
      <qradmin v-if="isQrcode" @close="isQrcode = false" :unitBox="unitIDBox" />
    </transition>

    <transition name="el-fade-in">
      <commandsTemplate
        v-if="isAddCommandTemplate"
        @close="handlCloseCommandTemplate"
        :title="1"
      />
    </transition>

    <transition name="el-fade-in">
      <commandsTemplate
        v-if="isEditCommandTemplate"
        @close="isEditCommandTemplate = false"
        :currentcommand="currentCommand"
        :title="2"
      />
    </transition>

    <transition>
      <create-payment
        v-if="isShowCreatePayment"
        :companies="companies"
        @close-create-payment="closeCreatePayment"
      />
    </transition>
  </div>
</template>

<script>
import {
  adminPanel,
  companyApi,
  dealersApi,
  feedbackApi,
  geozonesApi,
  integratorsApi,
  loginApi,
  mailingsApi,
  notificationsApi,
  objectsApi,
  racesApi,
  reportsApi,
  routesApi,
  trailersApi
} from '@/api'
import eventBus from '@/eventBus.js'
import defaultDate from '@/utils/helpers.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import {FINANCICAL_REPORT_URL} from "@/constants";

export default {
  components: {
    companiesList: () => import('./components/companiesList'),
    objectRecovery: () => import('./components/objectRecovery'),
    retranslators: () => import('./components/retranslators'),
    integrators: () => import('./components/integrators'),
    addTerminal: () => import('@/components/terminal/terminalModal.vue'),
    editTariff: () => import('./components/editTariff'),
    financeTable: () => import('./components/financeTable'),
    addCompany: () => import('./components/addCompany'),
    feedback: () => import('./components/feedback'),
    qradmin: () => import('./components/QRadmin'),
    commandsTemplate: () => import('./components/commandsTemplate'),
    copyReportTemplate: () => import('./components/copyReportTemplate'),
    QrIcon: () => import('./components/assets/icons/ObjectQRIcon.vue'),
    AddIcon: () => import('./components/assets/icons/AddIcon.vue'),
    ArrowIcon: () => import('./components/assets/icons/ArrowIcon'),
    CreatePayment: () => import('./components/CreatePayment.vue'),
    OutTrashIcon: () =>
      import(
        '@/components/admin-panel/components/assets/icons/OutTrashIcon.vue'
      )
  },

  props: ['user-updated', 'update-item', 'geoclose', 'update-company'],

  data() {
    // ACTIVATE THE COMPY TEMPLATE COMPONENET
    return {
      templateToCopy: null,
      isCopyTemplate: false,
      isAddCommandTemplate: false,
      isEditCommandTemplate: false,
      currentCommand: null,
      unitIDBox: '',
      isQrcode: false,
      newIntegrator: {
        name: '',
        inn: '',
        phone: '',
        email: '',
        contact_person: '',
        regions: []
      },
      isEditIntegrator: false,
      isAddIntegrator: false,
      currentUnit: {},
      currentCompany: '',
      isFinance: false,
      currentTariff: '',
      isAddTerminals: false,
      isAddRetranslators: false,
      isEditRetranslators: false,
      isObjectRecover: false,
      popVisible: false,
      isCompaniesList: false,
      companyToCopy: '',
      financeReport: `${
        process.env.NODE_ENV === 'development' ? 'http' : 'https'
      }://${location.host}/api_v1/finance_excel?body={}`,
      company: [],
      isCompanyMissing: false,
      aModule: 'admin_actions',
      filterString: '',
      dates: [],

      pickerOptions: {
        firstDayOfWeek: 1
      },
      elementsArr: [],
      formData: {
        from: 0,
        count: 100,
        value: this.filterString
      },
      pageNumber: 1,
      pageSize: 100,
      maxRows: 0,
      elementsSelected: [],
      AllelementsSelected: [],
      isAllChecked: false,
      isIndeterminate: false,
      stateIcon: 'normal',
      canDelete: true,
      objectToRecover: '',
      panelCompanies: [],
      isEditTarif: false,
      currentIndex: '',
      isGeoAdmin: false,
      unitImeis: [],
      isInviteUser: false,
      isAddCompany: false,
      isFeedBack: false,
      isImportWialon: false,
      isImportKml: false,
      isShowCreatePayment: false,
      selectObject: [],
      dataSelectDeletions: [],
      startDateDeletions: moment()
        .subtract(5, 'years')
        .format('YYYY-MM-DD HH:mm:ss'),
      finishDateDeletions: moment().format('YYYY-MM-DD HH:mm:ss'),
      objDataMessage: {},
      dateDeletionsDate: []
    }
  },

  computed: {
    ...mapGetters({
      companies: 'login/companies',
      protocols: 'dictionary/protocol',
      payModules: 'properties/payModules',
      organizationType: 'properties/organizationType',
      tariffType: 'properties/tariffType',
      me: 'login/me',
      adminModules: 'dictionary/adminModules',
      tariffs: 'billing/tarrifs',
      currentRole: 'login/role'
    })
  },

  watch: {
    // заккоментил, чтобы не отправлялся лишний запрос на admin_query
    // me() {
    //   this.getData()
    // },
    geoclose() {
      this.isGeoAdmin = false
    },
    tariffs(val) {
      console.log('changed', val)
    },
    company(val) {
      this.panelCompanies = []
      val.forEach((val) => {
        this.panelCompanies.push({ id: val })
      })
      this.$emit('company-for-wialon', this.panelCompanies[0].id)
      // выбрали комапнию запросим данные
      this.getData()
    },
    panelCompanies(val) {
      this.isCompanyMissing = val.length > 0
    },
    aModule(val) {
      this.formData.from = 0
      this.pageNumber = 1
      this.formData.value = ''
      this.filterString = ''
      this.cancel()
      this.aModule = val
      this.getData()

      if (this.isShowCreatePayment && val !== 'payments') {
        this.isShowCreatePayment = false
      }

      if (val === 'payments') {
        this.$nextTick(() => {
          const paymentTable = document.querySelector('.payment-table')
          paymentTable.addEventListener('click', this.hideCreatePayments)
        })
      } else {
        const paymentTable = document.querySelector('.payment-table')
        if (!paymentTable) return
        paymentTable.removeEventListener('click', this.hideCreatePayments)
      }
    },
    updateItem() {
      this.getData()
    },
    userUpdated() {
      adminPanel.getExtraUnits(
        { ...this.formData, model: 'users' },
        (response) => {
          this.elementsArr = response.data.list
          this.maxRows = response.data.max
        }
      )
    },
    updateCompany(newVal) {
      if (newVal) {
        const unit = this.elementsArr.find((item) => item.id === newVal.id)
        if (!unit) return
        Object.assign(unit, newVal)
      }
    },
    dataSelectDeletions() {
      if (this.dataSelectDeletions.length) {
        this.getDataDeletionsMessage()
      }
    }
  },

  methods: {
    ...mapMutations({
      setTerminals: 'terminal/setTerminals'
    }),
    ...mapActions({
      getProtocol: 'dictionary/getDictionaryByType',
      getMe: 'login/getMe',
      getTransactions: 'payments/getTransactions'
    }),
    getDays(tariff) {
      let days
      if (tariff.length === 1) {
        days = 'день'
      } else if (tariff.length >= 2 && tariff.length <= 4) {
        days = 'дня'
      } else {
        days = 'дней'
      }
      return `${tariff.length} ${days}`
    },

    hideCreatePayments() {
      if (this.isShowCreatePayment) {
        this.isShowCreatePayment = false
      }
    },

    humanOperationType(type) {
      const types = [
        {
          type: 'CASH_DEPOSIT',
          name: this.$t('adminPanelTranlsater.cashType.deposit')
        },
        {
          type: 'CARD_DEPOSIT',
          name: this.$t('adminPanelTranlsater.cashType.card')
        },
        {
          type: 'WITHDRAWAL',
          name: this.$t('adminPanelTranlsater.cashType.withdrawal')
        },
        {
          type: 'TRIAL_ACTIVATION',
          name: this.$t('adminPanelTranlsater.cashType.trial')
        }
      ]
      return types.find((item) => item.type === type).name || {}
    },

    closeDeletedNotification() {
      setTimeout(() => {
        this.$notify.closeAll()
      }, 10000)
    },

    recoverItems() {
      this.$notify.closeAll()
    },

    setProtocol(protocol) {
      let name = ''
      this.protocols.forEach((el) => {
        if (el.key === protocol) {
          name = el.value
        }
      })
      return name
    },

    openWialon() {
      this.isImportWialon = true
      this.$emit('open-wialon', this.isImportWialon)
    },

    openKml() {
      this.isImportKml = true
      this.$emit('open-kml', this.isImportKml)
    },

    closeAddCompany(company) {
      if (company) {
        this.elementsArr.push(company)
      }

      this.isAddCompany = false
    },

    handlQRclick(unit) {
      loginApi.crateCode(
        { units: [{ id: unit.id }] },
        (response) => {
          this.unitIDBox = response.data
          this.isQrcode = true
        },
        (err) => {
          console.log(err)
        }
      )
    },

    inviteUser() {
      // this.isInviteUser = true
      this.isAddCompany = true
    },

    inviteToCompany() {
      this.$prompt(
        this.$t('adminPanelTranlsater.enterEmail'),
        this.$t('adminPanelTranlsater.invite'),
        {
          confirmButtonText: this.$t('adminPanelTranlsater.invite'),
          // cancelButtonText: 'Отмена',
          showCancelButton: false,
          customClass: 'inviteToCompanyBox',
          inputPattern:
            /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: this.$t('adminPanelTranlsater.wrongEmail')
        }
      ).then(({ value }) => {
        dealersApi.inviteDealer(
          { email: value },
          (res) => {
            this.$notify.info({
              message: res.data.message
            })
          },
          (error) => {
            this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: error.response.data.message
            })
          }
        )
      })
    },

    showHistoryUsers() {
      console.log('show history users')
    },

    closeDialogInteg(e) {
      this.newIntegrator = {
        name: '',
        inn: '',
        phone: '',
        email: '',
        contact_person: '',
        regions: []
      }
      if (e) {
        const index = this.elementsArr.findIndex((el) => el.id === e.id)
        if (index !== -1) {
          this.elementsArr[index] = e
        } else {
          this.elementsArr.push(e)
        }
      }
      this.isEditIntegrator = false
      this.isAddIntegrator = false
    },
    closeDialogRetranslators(e) {
      if (e) {
        const index = this.elementsArr.findIndex((el) => el.id === e.id)
        if (index !== -1) {
          this.elementsArr[index] = e
        } else {
          this.elementsArr.push(e)
        }
      }
      this.isAddRetranslators = false
      this.isEditRetranslators = false
    },
    handleCloseUpdateTarif(el) {
      this.isEditTarif = false

      this.elementsArr[this.currentIndex] = el
    },
    getTarif(tariff, index) {
      this.currentTariff = tariff
      this.currentIndex = index
      this.isEditTarif = true
    },
    closeDialogTerminal(e) {
      if (e) {
        e.forEach((val) => {
          this.elementsArr.push(val)
        })
      }
      this.isAddTerminals = false
    },

    addTerminals() {
      this.isAddTerminals = true
    },
    addRetranslators() {
      this.currentUnit = {}
      this.isAddRetranslators = true
    },
    addCommandTemplate() {
      this.isAddCommandTemplate = true
    },
    addIntegrator() {
      this.currentUnit = {}
      this.isAddIntegrator = true
    },
    handlRecoveredObject() {
      const newList = this.elementsArr.filter(
        (item) => item.id !== this.objectToRecover.id
      )
      this.elementsArr = newList
    },
    recoverObject(unit) {
      this.isObjectRecover = true
      this.objectToRecover = unit
    },
    handlCloseDialog() {
      this.isCompaniesList = false
    },
    handlCloseDialogTemplates() {
      this.isCopyTemplate = false
    },
    copyObject(unit) {
      this.companyToCopy = unit
      this.isCompaniesList = true
    },
    copyTemplate(unit) {
      this.templateToCopy = unit
      this.isCopyTemplate = true
    },
    getElement(unit) {
      const module = this.aModule.replace('admin_', '')
      switch (module) {
        case 'commands_templates':
          this.currentCommand = unit
          this.isEditCommandTemplate = true
          break
        case 'feedback':
          break
        case 'integrators':
          this.currentUnit = unit
          this.isEditIntegrator = true
          break
        case 'retranslators':
          adminPanel.getUnits(
            {
              ...this.formData,
              companies: this.panelCompanies,
              model: 'terminals'
            },
            (response) => {
              this.unitImeis = response.data.list
            },
            (error) => {
              eventBus.$showError(error.response.data.message)
              // reject(error.response.data.message)
            }
          )
          this.isEditRetranslators = true
          this.currentUnit = unit
          break
        case 'routes':
          this.$emit('admin-edit-route', unit)
          break
        case 'geozones':
          geozonesApi.getGeozone(unit.id, (res) => {
            if (res.data && Object.keys(res.data).length) {
              this.$store.commit('geozones/ADD_GEOZONE', res.data)
              setTimeout(() => {
                this.$emit('admin-edit-geozone', res.data)
                this.isGeoAdmin = true
              }, 80)
            } else {
              this.$showError(this.$t('adminPanelTranlsater.notData'))
            }
          })
          break
        case 'trips':
          this.$emit('admin-edit-trip', unit)
          break
        case 'unitsgroup':
          this.$emit('admin-edit-groups', unit)
          break
        case 'terminals':
          this.$emit('admin-edit-terminals', unit)
          break
        case 'units':
          this.$emit('update-unit', {
            unitId: unit.id,
            fromModule: 'admin-panel'
          })
          break
        case 'delunits':
          this.$emit('update-unit', {
            unitId: unit.id,
            fromModule: 'admin-panel'
          })
          break
        case 'companies':
          this.$emit('admin-companies', unit.id)
          break
        case 'users':
          this.$emit('admin-users', unit.id)
          break
        case 'trailers':
          this.$emit('admin-trailer', unit.id)
          break
        case 'mailings':
          this.$emit('admin-mailings', unit.id)
          break
        case 'report_templates':
          reportsApi.getReportTemplateId(unit.id, (res) => {
            this.$emit('admin-reports-template', res.data)
          })
          break
        case 'notifications':
          notificationsApi.getNotificationById(unit.id, (res) => {
            this.$emit('admin-notifications', res.data)
          })
          break
        default:
          break
      }
    },

    openAlert() {
      this.$confirm(this.$t('adminPanelTranlsater.acceptDeleteTemplate'), '', {
        confirmButtonText: this.$t('adminPanelTranlsater.delete'),
        cancelButtonText: this.$t('adminPanelTranlsater.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.elementsSelected.forEach((el) => {
            adminPanel.deleteCommandTemplate(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
              this.cancel()
            })
          })
        })
        .catch(() => {
          console.log('error')
        })
    },
    async deleteElements() {
      const module = this.aModule.replace('admin_', '')

      switch (module) {
        case 'commands_templates':
          this.openAlert()

          break
        case 'geozones':
          this.elementsSelected.forEach((el) => {
            geozonesApi.deleteGeozone(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })

          this.cancel()
          this.getData()
          break
        case 'integrators':
          this.elementsSelected.forEach((el) => {
            integratorsApi.deleteIntegrator(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })
          this.cancel()
          this.getData()

          break
        case 'units':
          this.elementsSelected.forEach((el) => {
            objectsApi.deleteObject(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })
          this.cancel()
          this.getData()

          break
        case 'unitsgroup':
          this.elementsSelected.forEach((el) => {
            objectsApi.deleteUnitGroup(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })
          this.cancel()
          this.getData()

          break
        case 'companies':
          if (this.elementsSelected.length > 1) {
            this.cancel()
            return this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: 'Нельзя удалить более 1 компаний за один запрос'
            })
          }
          const ids = []
          this.elementsSelected.forEach((el) => {
            ids.push(el)
          })

          await companyApi
            .deleteCompany(ids)
            .then(() => {
              this.elementsSelected.forEach((el) => {
                this.elementsArr = this.elementsArr.filter(
                  (element) => element.id !== el.id
                )
              })
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: `<div class='confirmation-container' >
                    <span class="" > ${
                      this.elementsSelected.length <= 1
                        ? this.$t('one_element_deleted')
                        : `${this.elementsSelected.length} ${this.$t(
                            'already_deleted_elements'
                          )}`
                    } </span>
                    <span id='confirm' class='cursor-pointer cancelConfirm text-annotationColor'>${this.$t(
                      'cancel'
                    )}</span>
                  </div>`,
                duration: 10000,
                position: 'bottom-left',
                customClass: 'confirmation'
              })
              const confirmation = document.getElementById('confirm')
              const self = this
              confirmation.addEventListener(
                'click',
                async function () {
                  await companyApi
                    .cancelDeleteCompany(ids)
                    .then(() => {
                      self.elementsArr = [...self.elementsArr, ...ids]
                      self.$notify.closeAll()
                    })
                    .catch((err) => {
                      return self.$notify.error({
                        duration: 5000,
                        title: `${self.$t('error')} \u{1f62a}`,
                        message: err.response.data.message
                      })
                    })
                },
                false
              )
              this.cancel()
              setTimeout(() => {
                this.getData()
                this.getMe()
              }, 13000)
            })
            .catch((err) => {
              this.cancel()
              return this.$notify.error({
                duration: 5000,
                title: `${this.$t('error')} \u{1f62a}`,
                message: err.response.data.message
              })
            })

          break
        case 'routes':
          this.elementsSelected.forEach((el) => {
            routesApi.deleteRoute(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })
          this.cancel()
          this.getData()
          break
        case 'objects':
          break
        case 'users':
          this.elementsSelected.forEach((el) =>
            adminPanel.deleteUserFromAdminPanel(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          )

          this.cancel()
          this.getData()

          break
        case 'trailers':
          this.elementsSelected.forEach((el) => {
            trailersApi.deleteTrailer(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })

          this.cancel()
          this.getData()
          break
        case 'mailings':
          this.elementsSelected.forEach((el) => {
            mailingsApi.deleteMailing(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })

          this.cancel()
          this.getData()
          break
        case 'trips':
          this.elementsSelected.forEach((el) => {
            racesApi.deleteRace(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })

          this.cancel()
          this.getData()
          break
        case 'report_templates':
          this.elementsSelected.forEach((el) => {
            reportsApi.deleteReportTemplates(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })

          this.cancel()
          this.getData()
          break
        case 'notifications':
          this.elementsSelected.forEach((el) => {
            notificationsApi.deleteNotification(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })

          this.cancel()
          this.getData()
          break
        case 'terminals':
          this.elementsSelected.forEach((el) => {
            adminPanel.delTerminals(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })
          this.cancel()
          this.getData()
          break
        case 'retranslators':
          this.elementsSelected.forEach((el) => {
            adminPanel.delRetranslator(el.id, () => {
              this.elementsArr = this.elementsArr.filter(
                (element) => element.id !== el.id
              )
            })
          })
          this.cancel()
          this.getData()
          break
        default:
          break
      }
    },
    cancel() {
      this.stateIcon = 'normal'
      this.isAllChecked = false
      this.isIndeterminate = false
      this.elementsSelected.forEach((el) => {
        el.isInArray = false
      })
      this.elementsSelected = []
    },
    changeChecked(unit, $event) {
      this.elementsSelected = $event.target.checked
        ? [...this.elementsSelected, unit]
        : this.elementsSelected.filter((el) => el.id !== unit.id)
      const selectedLength = this.elementsSelected.length

      if (selectedLength === this.elementsArr.length) {
        this.isAllChecked = true
        this.isIndeterminate = true
        this.stateIcon = 'select'
      } else if (!selectedLength) {
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
        this.stateIcon = 'select'
      }
    },
    checkAllUsers($event) {
      if ($event.target.checked) {
        this.elementsSelected = this.elementsArr
        this.elementsSelected.forEach((el) => {
          el.isInArray = true
        })
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else {
        this.elementsSelected.forEach((el) => {
          el.isInArray = false
        })
        this.elementsSelected = []
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      }
    },
    handlSearch() {
      this.formData.value = this.filterString
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.from = (this.pageNumber - 1) * this.pageSize
      this.getData()
    },
    async handlCloseCommandTemplate() {
      this.$nextTick(() => this.getData())
      this.$nextTick(() => {
        this.isAddCommandTemplate = false
      })
    },
    getData() {
      this.elementsArr = []
      if (this.aModule === 'admin_users') {
        this.canDelete = false
        adminPanel.getExtraUnits(
          { ...this.formData, model: 'users', companies: this.panelCompanies },
          (response) => {
            this.elementsArr = response.data.list
            this.maxRows = response.data.max
          }
        )
      } else if (this.aModule === 'admin_companies') {
        // this.canDelete = false

        adminPanel.getExtraUnits(
          {
            ...this.formData,
            model: 'companies',
            companies: this.panelCompanies
          },
          (response) => {
            this.elementsArr = response.data.list
            this.maxRows = response.data.max
          }
        )
      } else if (this.aModule === 'admin_actions') {
        adminPanel.getUnits(
          {
            ...this.formData,
            companies: this.panelCompanies,
            model: this.aModule.replace('admin_', '')
          },
          (response) => {
            this.elementsArr = response.data.list
            this.setTerminals(this.elementsArr)
            this.maxRows = response.data.max
          },
          (error) => {
            eventBus.$showError(error.response.data.message)
            // reject(error.response.data.message)
          }
        )
      } else if (this.aModule === 'admin_integrators') {
        // integratorsApi.getRegions(
        //   (response) => {
        //     this.regions = response.data
        //     this.$store.dispatch('login/ADD_REGIONS', response.data)
        //   },
        //   (err) => console.log(err)
        // )
        integratorsApi.getIntegrators(
          this.formData.value,
          (response) => {
            this.elementsArr = response.data.list
            this.maxRows = response.data.max
          },
          (err) => {
            console.log(err)
          }
        )
      } else if (this.aModule === 'feedback') {
        feedbackApi.getFeedbackList(this.formData, (response) => {
          this.elementsArr = response.data.list
          this.maxRows = response.data.max
          // console.log('wokwowkwo2kwo', this.elementsArr)
        })
      } else if (this.aModule === 'payments') {
        if (this.panelCompanies.length > 0) {
          this.isCompanyMissing = false
          this.getPayments()
        } else {
          this.isCompanyMissing = true
        }
      } else if (this.aModule === 'admin_tariffs') {
        if (this.panelCompanies.length > 0) {
          this.isCompanyMissing = false
          const formData = {
            max_rows: 100,
            first_row: 0,
            from: this.dates[0],
            to: this.dates[1],
            companies: this.panelCompanies
          }
          this.$store
            .dispatch('billing/GET_TARIFFS', formData)
            .then((response) => {
              this.elementsArr = response.data
              this.elementsArr.forEach((el) => {
                this.companies.forEach((comp) => {
                  if (el.company_id === comp.id) {
                    el.companyDetails = comp
                  }
                })
              })
            })
        } else {
          this.isCompanyMissing = true
        }
      } else if (this.aModule === 'commands_templates') {
        adminPanel.getUnits(
          {
            ...this.formData,
            companies: this.panelCompanies,
            model: this.aModule.replace('admin_', '')
          },
          (response) => {
            this.elementsArr = response.data.list

            this.maxRows = response.data.max
          },
          (error) => {
            eventBus.$showError(error.response.data.message)
            // reject(error.response.data.message)
          }
        )
      } else if (this.aModule === 'data_deletions') {
        if (this.panelCompanies.length) {
          this.isCompanyMissing = false
          adminPanel.getUnits(
            {
              ...this.formData,
              companies: this.panelCompanies,
              model: 'units'
            },
            (response) => {
              this.selectObject = response.data.list
            },
            (error) => {
              eventBus.$showError(error.response.data.message)
              // reject(error.response.data.message)
            }
          )

          this.getDataDeletionsMessage()
        } else {
          this.isCompanyMissing = true
        }
      } else {
        this.canDelete = true
        adminPanel.getUnits(
          {
            ...this.formData,
            companies: this.panelCompanies,
            model: this.aModule.replace('admin_', '')
          },
          (response) => {
            this.elementsArr = response.data.list
            this.setTerminals(this.elementsArr)
            this.maxRows = response.data.max
          },
          (error) => {
            eventBus.$showError(error.response.data.message)
            // reject(error.response.data.message)
          }
        )
      }
    },
    removeDataDeletions() {
      this.elementsSelected.forEach((el) => {
        adminPanel.deleteDataDeletions(el.id)
      })

      setTimeout(() => {
        this.getDataDeletionsMessage()
      }, 1000)

      this.elementsSelected = []
      this.indeterminate = false
      if (this.isAllChecked) {
        this.isAllChecked = false
      }
    },
    getDataDeletionsMessage() {
      const conditions = [
        {
          field: 'start_date',
          value: this.startDateDeletions
        },
        {
          field: 'finish_date',
          value: this.finishDateDeletions
        }
      ]
      if (this.dataSelectDeletions) {
        conditions.push({
          field: 'unit.id',
          value: this.dataSelectDeletions
        })
      }
      adminPanel
        .dataDeletions({
          conditions,
          companies: this.panelCompanies
        })
        .then((resp) => {
          this.elementsArr = resp.list
          this.objDataMessage = resp.list.reduce((obj, item) => {
            obj[item.id] = item
            return obj
          }, {})
        })
    },
    prevPage() {
      this.pageNumber--
    },

    nextPage() {
      this.pageNumber++
    },
    getFinanceReport() {
      const url = `${window.location.origin}/api_v1/finance_excel?body=%7B%7D`
      console.log('getFinanceReport', url)
      window.open(url)
    },
    humanDate(date) {
      const humanDate = {
        date: moment(date).format('YYYY-MM-DD'),
        time: moment(date).format('HH:mm')
      }
      return humanDate
    },
    showCreatePayment() {
      this.isShowCreatePayment = !this.isShowCreatePayment
    },
    closeCreatePayment() {
      this.getPayments()
      this.isShowCreatePayment = false
    },
    getPayments() {
      const formData = {
        max_rows: 100,
        first_row: this.formData.from,
        // from: this.dates[0],
        // to: this.dates[1],
        companies: this.panelCompanies,
        search: this.filterString
      }
      this.getTransactions(formData).then((response) => {
        this.elementsArr = response.data.transactions
        this.maxRows = response.data.max
        this.elementsArr.forEach((el) => {
          this.companies.forEach((company) => {
            if (el.company_id === company.id) {
              el.companyDetails = company
            }
          })
        })
      })
    }
  },
  mounted() {
    this.dateDeletionsDate = [this.startDateDeletions, this.finishDateDeletions]
  },

  created() {
    this.dates = defaultDate()
    this.getProtocol('retranslator_protocols')
  }
}
</script>

<style>
.leva {
  background: red !important;
  max-width: 80vw !important;
}
.inviteToCompanyBox span {
  color: #5477a9;
  font-family: Source Sans Pro;
}
.inviteToCompanyBox button span {
  color: white !important;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: Source Sans Pro;
}
.inviteToCompanyBox .el-button--primary {
  background-color: #5477a9;
  border: none;
  border-radius: 7px;
  outline: none;
}
.invisible {
  opacity: 0;
}
.visible {
  opacity: 1;
}
.isInvalid .el-select {
  border: 1px solid #f56c6c !important;
  border-radius: 5px;
}
.adminSelect .el-select__tags-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>

<style lang="stylus" scoped>
.payments
  .title
    font-weight 600
    font-size 14px
    color #476e9f
    text-overflow ellipsis
    overflow hidden
  .text
    font-weight 400
    font-size 12px
    color #476E9F
    &--balanceMinus
      color red
  .amount-wrapper
    .text
      &:nth-of-type(1)
        margin-right 4px
      &:nth-of-type(2)
        margin-left 4px

.add-icon {
  display flex
  align-items center
  justify-content center
  transition all .3s
  &--active {
    transform rotate(45deg)
  }
}

.string
  &--isRed
    background #FFEEEE
  &--isBlue
    background #EAF3FE

.badge
  position absolute
  top 0
  right 40px
</style>
